import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Paper, Grid, CircularProgress, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { getOrderStatus } from '../services/api';

const OrderStatus = () => {
  const [externalId, setExternalId] = useState('');
  const [orderStatus, setOrderStatus] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.externalId) {
      setExternalId(location.state.externalId);
      handleSubmit(location.state.externalId);
    }
  }, [location.state]);

  const handleSubmit = async (id = null) => {
    setIsLoading(true);
    setError('');
    try {
      const response = await getOrderStatus(id || externalId);
      console.log('Order status response:', response.data);
      setOrderStatus(response.data);
    } catch (err) {
      console.error('Error fetching order status:', err);
      setError('Error fetching order status: ' + (err.response?.data?.error || err.message));
      setOrderStatus(null);
    } finally {
      setIsLoading(false);
    }
  };

  const renderLineItems = () => {
    if (!orderStatus || !orderStatus.lineItems || orderStatus.lineItems.length === 0) {
      return <Typography>No line items available</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ASIN</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Subtotal</TableCell>
              <TableCell>Delivery Range</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderStatus.lineItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.asin || 'N/A'}</TableCell>
                <TableCell>{item.quantity || 'N/A'}</TableCell>
                <TableCell>${item.price?.toFixed(2) || 'N/A'}</TableCell>
                <TableCell>${item.subtotal?.toFixed(2) || 'N/A'}</TableCell>
                <TableCell>
                  {item.deliveryRange?.start && item.deliveryRange?.end
                    ? `${new Date(item.deliveryRange.start).toLocaleDateString()} - ${new Date(item.deliveryRange.end).toLocaleDateString()}`
                    : 'N/A'}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderCharges = () => {
    if (!orderStatus || !orderStatus.charges || orderStatus.charges.length === 0) {
      return <Typography>No charges available</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderStatus.charges.map((charge, index) => (
              <TableRow key={index}>
                <TableCell>{charge.category || 'N/A'}</TableCell>
                <TableCell>{charge.type || 'N/A'}</TableCell>
                <TableCell>${charge.amount?.toFixed(2) || 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderShipments = () => {
    if (!orderStatus || !orderStatus.shipments || orderStatus.shipments.length === 0) {
      return <Typography>No shipment information available</Typography>;
    }

    return orderStatus.shipments.map((shipment, index) => (
      <Paper key={index} elevation={2} style={{ padding: '10px', marginTop: '10px' }}>
        <Typography>Shipment ID: {shipment.shipmentId || 'N/A'}</Typography>
        {shipment.packages && shipment.packages.map((pkg, pkgIndex) => (
          <div key={pkgIndex}>
            <Typography>Tracking Number: {pkg.trackingNumber || 'N/A'}</Typography>
            <Typography>Carrier: {pkg.carrier || 'N/A'}</Typography>
            <Typography>
              Estimated Delivery: {
                pkg.estimatedDelivery?.start && pkg.estimatedDelivery?.end
                  ? `${new Date(pkg.estimatedDelivery.start).toLocaleDateString()} - ${new Date(pkg.estimatedDelivery.end).toLocaleDateString()}`
                  : 'N/A'
              }
            </Typography>
          </div>
        ))}
      </Paper>
    ));
  };

  return (
    <Container>
      <Button onClick={() => navigate('/dashboard')} style={{ marginBottom: '20px' }}>Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Order Status
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
          <TextField
            label="External Order ID"
            value={externalId}
            onChange={(e) => setExternalId(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : 'Check Status'}
          </Button>
        </form>
        
        {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
        
        {orderStatus && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="h6">Order Status</Typography>
            <Typography>Amazon Order ID: {orderStatus.amazonOrderId || 'N/A'}</Typography>
            <Typography>Status: {orderStatus.status || 'N/A'}</Typography>
            <Typography>External ID: {orderStatus.externalId || 'N/A'}</Typography>
            
            <Typography variant="h6" style={{ marginTop: '20px' }}>Line Items</Typography>
            {renderLineItems()}

            <Typography variant="h6" style={{ marginTop: '20px' }}>Charges</Typography>
            {renderCharges()}

            <Typography variant="h6" style={{ marginTop: '20px' }}>Shipments</Typography>
            {renderShipments()}
          </div>
        )}
      </Paper>
    </Container>
  );
};

export default OrderStatus;

















// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { TextField, Button, Typography, Paper, Grid, CircularProgress, Container } from '@mui/material';
// import { getOrderStatus } from '../services/api';

// const OrderStatus = () => {
//   const [externalId, setExternalId] = useState('');
//   const [orderStatus, setOrderStatus] = useState(null);
//   const [error, setError] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (location.state && location.state.externalId) {
//       setExternalId(location.state.externalId);
//       handleSubmit(location.state.externalId);
//     }
//   }, [location.state]);

//   const handleSubmit = async (id = null) => {
//     setIsLoading(true);
//     setError('');
//     try {
//       const response = await getOrderStatus(id || externalId);
//       setOrderStatus(response.data);
//     } catch (err) {
//       setError('Error fetching order status: ' + (err.response?.data?.error || err.message));
//       setOrderStatus(null);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <Container>
//       <Button onClick={() => navigate('/dashboard')} style={{ marginBottom: '20px' }}>Back to Dashboard</Button>
//       <Typography variant="h4" component="h1" gutterBottom>
//         Order Status
//       </Typography>
//       <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
//         <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
//           <TextField
//             label="External Order ID"
//             value={externalId}
//             onChange={(e) => setExternalId(e.target.value)}
//             required
//             fullWidth
//             margin="normal"
//           />
//           <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
//             {isLoading ? <CircularProgress size={24} /> : 'Check Status'}
//           </Button>
//         </form>
        
//         {error && <Typography color="error" style={{ marginTop: '20px' }}>{error}</Typography>}
        
//         {orderStatus && (
//           <div style={{ marginTop: '20px' }}>
//             <Typography variant="h6">Order Status</Typography>
//             <Typography>Amazon Order ID: {orderStatus.amazonOrderId}</Typography>
//             <Typography>Status: {orderStatus.status}</Typography>
            
//             <Typography variant="subtitle1" style={{ marginTop: '10px' }}>Line Items:</Typography>
//             <Grid container spacing={2}>
//               {orderStatus.lineItems.map((item, index) => (
//                 <Grid item xs={12} sm={6} md={4} key={index}>
//                   <Paper elevation={2} style={{ padding: '10px' }}>
//                     <Typography>ASIN: {item.asin}</Typography>
//                     <Typography>Quantity: {item.quantity}</Typography>
//                     <Typography>Price: ${item.price}</Typography>
//                     <Typography>Delivery: {new Date(item.deliveryRange.start).toLocaleDateString()} - {new Date(item.deliveryRange.end).toLocaleDateString()}</Typography>
//                   </Paper>
//                 </Grid>
//               ))}
//             </Grid>
            
//             <Typography variant="subtitle1" style={{ marginTop: '20px' }}>Charges:</Typography>
//             {orderStatus.charges.map((charge, index) => (
//               <Typography key={index}>
//                 {charge.category} ({charge.type}): ${charge.amount}
//               </Typography>
//             ))}
            
//             {orderStatus.shipmentInfo && (
//               <div style={{ marginTop: '20px' }}>
//                 <Typography variant="subtitle1">Shipment Information:</Typography>
//                 <Typography>Shipment ID: {orderStatus.shipmentInfo.shipmentId}</Typography>
//                 <Grid container spacing={2}>
//                   {orderStatus.shipmentInfo.packages.map((pkg, index) => (
//                     <Grid item xs={12} sm={6} md={4} key={index}>
//                       <Paper elevation={2} style={{ padding: '10px' }}>
//                         <Typography>Tracking Number: {pkg.trackingNumber}</Typography>
//                         <Typography>Carrier: {pkg.carrier}</Typography>
//                         <Typography>Estimated Delivery: {new Date(pkg.estimatedDelivery.start).toLocaleDateString()} - {new Date(pkg.estimatedDelivery.end).toLocaleDateString()}</Typography>
//                       </Paper>
//                     </Grid>
//                   ))}
//                 </Grid>
//               </div>
//             )}
//           </div>
//         )}
//       </Paper>
//     </Container>
//   );
// };

// export default OrderStatus;