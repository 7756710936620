import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Grid, Paper, Snackbar, CircularProgress, IconButton, Switch, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, Box } from '@mui/material';
import { getUserRequests, downloadRequestJson } from '../services/api';
import { useNavigate } from 'react-router-dom';

const UserRequests = () => {
  const [apiRequests, setApiRequests] = useState([]);
  const [uiRequests, setUiRequests] = useState([]);
  const [orders, setOrders] = useState([]);
  const [error, setError] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserRequests();
  }, []);

  const fetchUserRequests = async () => {
    setIsLoading(true);
    try {
      const response = await getUserRequests();
      setApiRequests(response.data.apiRequests);
      setUiRequests(response.data.uiRequests);
      setOrders(response.data.orders);
    } catch (err) {
      setError('Error fetching user requests and orders');
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewJson = async (requestId) => {
    try {
      if (!requestId) {
        throw new Error('Request ID is undefined');
      }
      await downloadRequestJson(requestId);
    } catch (err) {
      console.error('Error downloading request JSON:', err);
      setError('Error downloading request JSON: ' + err.message);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderRequestsTable = (requests) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Endpoint</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.map((request) => (
            <TableRow key={request._id}>
              <TableCell>{request.endpoint}</TableCell>
              <TableCell>{new Date(request.timestamp).toLocaleString()}</TableCell>
              <TableCell>
                <Button 
                  variant="outlined" 
                  size="small" 
                  onClick={() => handleViewJson(request._id)}
                >
                  View JSON
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderOrdersTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>External ID</TableCell>
            <TableCell>Amazon Order ID</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow key={order.externalId}>
              <TableCell>{order.externalId}</TableCell>
              <TableCell>{order.amazonOrderId}</TableCell>
              <TableCell>{order.status}</TableCell>
              <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
              <TableCell>
                <Button 
                  variant="outlined" 
                  size="small" 
                  onClick={() => navigate(`/order-status`, { state: { externalId: order.externalId } })}
                >
                  View Status
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Your Activity
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="activity tabs">
          <Tab label="UI Requests" />
          <Tab label="API Requests" />
          <Tab label="Orders" />
        </Tabs>
      </Box>
      
      {tabValue === 0 && renderRequestsTable(uiRequests)}
      {tabValue === 1 && renderRequestsTable(apiRequests)}
      {tabValue === 2 && renderOrdersTable()}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Container>
  );
};

export default UserRequests;




// import React, { useState, useEffect } from 'react';
// import { 
//   Container, Typography, Table, TableBody, TableCell, TableContainer, 
//   TableHead, TableRow, Paper, Button, Tabs, Tab, Box, Modal
// } from '@mui/material';
// import { getUserRequests, downloadRequestJson } from '../services/api';
// import { useNavigate } from 'react-router-dom';

// const UserRequests = () => {
//   const [apiRequests, setApiRequests] = useState([]);
//   const [uiRequests, setUiRequests] = useState([]);
//   const [orders, setOrders] = useState([]);
//   const [error, setError] = useState('');
//   const [tabValue, setTabValue] = useState(0);
//   const [selectedJson, setSelectedJson] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchUserRequests();
//   }, []);

//   const fetchUserRequests = async () => {
//     try {
//       const response = await getUserRequests();
//       setApiRequests(response.data.apiRequests);
//       setUiRequests(response.data.uiRequests);
//       setOrders(response.data.orders);
//     } catch (err) {
//       setError('Error fetching user requests and orders');
//     }
//   };

//   const handleViewJson = async (requestId) => {
//     try {
//       const response = await downloadRequestJson(requestId);
//       setSelectedJson(JSON.stringify(response.data, null, 2));
//     } catch (err) {
//       setError('Error downloading request JSON');
//     }
//   };

//   const handleTabChange = (event, newValue) => {
//     setTabValue(newValue);
//   };

//   const renderRequestsTable = (requests) => (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>Endpoint</TableCell>
//             <TableCell>Date</TableCell>
//             <TableCell>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {requests.map((request) => (
//             <TableRow key={request.id}>
//               <TableCell>{request.endpoint}</TableCell>
//               <TableCell>{new Date(request.timestamp).toLocaleString()}</TableCell>
//               <TableCell>
//                 <Button 
//                   variant="outlined" 
//                   size="small" 
//                   onClick={() => handleViewJson(request.id)}
//                 >
//                   View JSON
//                 </Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );

//   const renderOrdersTable = () => (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             <TableCell>External ID</TableCell>
//             <TableCell>Amazon Order ID</TableCell>
//             <TableCell>Status</TableCell>
//             <TableCell>Date</TableCell>
//             <TableCell>Actions</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {orders.map((order) => (
//             <TableRow key={order.externalId}>
//               <TableCell>{order.externalId}</TableCell>
//               <TableCell>{order.amazonOrderId}</TableCell>
//               <TableCell>{order.status}</TableCell>
//               <TableCell>{new Date(order.createdAt).toLocaleString()}</TableCell>
//               <TableCell>
//                 <Button 
//                   variant="outlined" 
//                   size="small" 
//                   onClick={() => navigate(`/order-status`, { state: { externalId: order.externalId } })}
//                 >
//                   View Status
//                 </Button>
//               </TableCell>
//             </TableRow>
//           ))}
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );

//   return (
//     <Container>
//       <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
//       <Typography variant="h4" component="h1" gutterBottom>
//         Your Activity
//       </Typography>
//       {error && <Typography color="error">{error}</Typography>}
      
//       <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
//         <Tabs value={tabValue} onChange={handleTabChange} aria-label="activity tabs">
//           <Tab label="UI Requests" />
//           <Tab label="API Requests" />
//           <Tab label="Orders" />
//         </Tabs>
//       </Box>
      
//       {tabValue === 0 && renderRequestsTable(uiRequests)}
//       {tabValue === 1 && renderRequestsTable(apiRequests)}
//       {tabValue === 2 && renderOrdersTable()}

//       <Modal
//         open={selectedJson !== null}
//         onClose={() => setSelectedJson(null)}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={{
//           position: 'absolute',
//           top: '50%',
//           left: '50%',
//           transform: 'translate(-50%, -50%)',
//           width: 400,
//           bgcolor: 'background.paper',
//           border: '2px solid #000',
//           boxShadow: 24,
//           p: 4,
//           maxHeight: '80vh',
//           overflow: 'auto'
//         }}>
//           <Typography id="modal-modal-title" variant="h6" component="h2">
//             Request JSON
//           </Typography>
//           <pre id="modal-modal-description" sx={{ mt: 2 }}>
//             {selectedJson}
//           </pre>
//         </Box>
//       </Modal>
//     </Container>
//   );
// };

// export default UserRequests;