// src/components/UserManagement.js
import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, Typography, CircularProgress, TextField, TablePagination,
  Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import { getAllUsers, banUser, unbanUser, downloadUsersData } from '../services/adminApi';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getAllUsers();
      setUsers(data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching users');
      setLoading(false);
    }
  };

  const handleBanUser = async (userId) => {
    try {
      await banUser(userId);
      fetchUsers();
    } catch (err) {
      setError('Error banning user');
    }
  };

  const handleUnbanUser = async (userId) => {
    try {
      await unbanUser(userId);
      fetchUsers();
    } catch (err) {
      setError('Error unbanning user');
    }
  };

  const handleDownloadUsers = async () => {
    try {
      await downloadUsersData();
    } catch (err) {
      setError('Error downloading user data');
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.amazonEmail?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleDownloadUsers} style={{ marginBottom: '1rem' }}>
        Download Users Data
      </Button>
      <TextField
        fullWidth
        label="Search users"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '1rem' }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Amazon Email</TableCell>
              <TableCell>Amazon Connected</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.amazonEmail || 'N/A'}</TableCell>
                  <TableCell>{user.isAmazonConnected ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{user.isBanned ? 'Banned' : 'Active'}</TableCell>
                  <TableCell>
                    <Button variant="outlined" color="primary" onClick={() => handleOpenDialog(user)} style={{ marginRight: '0.5rem' }}>
                      Details
                    </Button>
                    {user.isBanned ? (
                      <Button variant="contained" color="primary" onClick={() => handleUnbanUser(user._id)}>
                        Unban
                      </Button>
                    ) : (
                      <Button variant="contained" color="secondary" onClick={() => handleBanUser(user._id)}>
                        Ban
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>User Details</DialogTitle>
        <DialogContent>
          {selectedUser && (
            <>
              <Typography><strong>Email:</strong> {selectedUser.email}</Typography>
              <Typography><strong>Amazon Email:</strong> {selectedUser.amazonEmail || 'N/A'}</Typography>
              <Typography><strong>Amazon Connected:</strong> {selectedUser.isAmazonConnected ? 'Yes' : 'No'}</Typography>
              <Typography><strong>Status:</strong> {selectedUser.isBanned ? 'Banned' : 'Active'}</Typography>
              <Typography><strong>Created At:</strong> {new Date(selectedUser.createdAt).toLocaleString()}</Typography>
              <Typography><strong>Last Updated:</strong> {new Date(selectedUser.updatedAt).toLocaleString()}</Typography>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserManagement;