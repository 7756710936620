import React, { useState } from 'react';
import { Container, Button, Typography, TextField, Grid, Card, CardContent, Box, CircularProgress, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate } from 'react-router-dom';
import { searchProductsByAsins } from '../services/api';

const MultiAsinSearch = () => {
  const [asins, setAsins] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async () => {
    setIsLoading(true);
    setError('');
    try {
      const asinList = asins.split(',').map(asin => asin.trim());
      const response = await searchProductsByAsins(asinList);
      console.log('API Response:', response);
      if (response.data && Array.isArray(response.data.products)) {
        setResults(response.data.products);
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      console.error('Search error:', err);
      setError('Error searching products: ' + (err.response?.data?.error || err.message));
      setIsSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const renderProductImages = (product) => {
    const images = product.images || [];
    if (images.length === 0) return <Typography>No images available</Typography>;

    return (
      <Carousel>
        {images.map((image, index) => (
          <div key={index}>
            <img 
              src={image.large || image.medium || image.small}
              alt={`Product Image ${index + 1}`}
              style={{ width: '100%', height: '300px', objectFit: 'contain' }}
            />
          </div>
        ))}
      </Carousel>
    );
  };

  const renderProductDetails = (product) => {
    return (
      <Box>
        <Typography variant="h6" component="div">{product.title || 'No Title'}</Typography>
        <Typography variant="body2" color="text.secondary">ASIN: {product.asin || 'N/A'}</Typography>
        <Typography variant="body1" paragraph>{product.productDescription || 'No description available'}</Typography>
        <Typography variant="h6">Offers:</Typography>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>View All Offers ({product.offers ? product.offers.length : 0})</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Offer ID</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Merchant</TableCell>
                    <TableCell>Fulfillment</TableCell>
                    <TableCell>Availability</TableCell>
                    <TableCell>Condition</TableCell>
                    <TableCell>Delivery</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {product.offers && product.offers.map((offer, index) => (
                    <TableRow key={index} style={offer.isFeaturedOffer ? { backgroundColor: '#e3f2fd' } : {}}>
                      <TableCell>{offer.offerId}</TableCell>
                      <TableCell>{offer.price?.formattedPrice || `$${offer.price?.value?.amount} ${offer.price?.value?.currencyCode}` || 'N/A'}</TableCell>
                      <TableCell>{offer.merchant?.name || 'N/A'}</TableCell>
                      <TableCell>{offer.fulfillmentType || 'N/A'}</TableCell>
                      <TableCell>{offer.availability || 'N/A'}</TableCell>
                      <TableCell>{offer.condition?.conditionValue || 'N/A'}</TableCell>
                      <TableCell>{offer.deliveryInformation || 'N/A'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  };

  return (
    <Container>
      <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Multi-ASIN Search
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Enter ASINs (comma-separated)"
        value={asins}
        onChange={(e) => setAsins(e.target.value)}
        margin="normal"
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSearch} 
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Search'}
      </Button>
      {error && <Typography color="error">{error}</Typography>}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {results.map((product) => (
          <Grid item xs={12} key={product.asin}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    {renderProductImages(product)}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {renderProductDetails(product)}
                    <Box mt={2}>
                      <Button 
                        component={Link} 
                        to={`/product/${product.asin}`} 
                        variant="outlined" 
                        style={{ marginRight: '10px' }}
                      >
                        View Details
                      </Button>
                      <Button 
                        component={Link} 
                        to={`/product/${product.asin}/offers`} 
                        variant="outlined" 
                      >
                        View Offers
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
        message={error}
      />
    </Container>
  );
};

export default MultiAsinSearch;