import React, { useState } from 'react';
import { Container, Typography, Button, Grid, Card, CardContent, CardMedia, TextField, Snackbar, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { searchProducts } from '../services/api';

const ProductSearch = () => {
  const [keyword, setKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [error, setError] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await searchProducts(keyword);
      console.log('Search response:', response);
      if (response.data && response.data.products) {
        setResults(response.data.products);
      } else {
        setError('No products found or unexpected response format');
        setIsSnackbarOpen(true);
      }
    } catch (err) {
      console.error('Search error:', err);
      setError('Error searching products: ' + (err.response?.data?.error || err.message));
      setIsSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  // const handleSearch = async () => {
  //   try {
  //     const response = await searchProducts(keyword);
  //     setResults(response.data.products || []);
  //     setError('');
  //   } catch (err) {
  //     console.error('Search error:', err);
  //     setError('Error searching products: ' + (err.response?.data?.error || err.message));
  //   }
  // };

  const handleDownloadJson = async () => {
    try {
      await searchProducts(keyword, true);
    } catch (err) {
      setError('Error downloading search results JSON: ' + (err.response?.data?.error || err.message));
      setIsSnackbarOpen(true);
    }
  };

  const getProductPrice = (product) => {
    const offers = product.includedDataTypes?.OFFERS;
    if (offers && offers.length > 0) {
      const price = offers[0].price;
      if (price.formattedPrice) {
        return price.formattedPrice;
      } else if (price.value && price.value.amount && price.value.currencyCode) {
        return `${price.value.currencyCode} ${price.value.amount}`;
      }
    }
    return 'Price not available';
  };

  return (
    <Container>
      <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Product Search
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        label="Search Keywords"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        margin="normal"
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleSearch} 
        style={{marginRight: '10px'}}
        disabled={isLoading}
      >
        {isLoading ? <CircularProgress size={24} /> : 'Search'}
      </Button>
      <Button variant="outlined" color="secondary" onClick={handleDownloadJson}>
        Download JSON
      </Button>
      {error && <Typography color="error">{error}</Typography>}
      <Grid container spacing={3} style={{ marginTop: '20px' }}>
        {results.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.asin}>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <CardMedia
                component="img"
                height="200"
                image={product.includedDataTypes?.IMAGES && product.includedDataTypes.IMAGES[0] ? product.includedDataTypes.IMAGES[0].large.url : 'placeholder-image-url'}
                alt={product.title || 'Product Image'}
                style={{ objectFit: 'contain' }}
              />
              <CardContent style={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" noWrap>
                  {product.title || 'No Title'}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  ASIN: {product.asin || 'N/A'}
                </Typography>
                <Typography variant="h6" color="primary">
                  {getProductPrice(product)}
                </Typography>
                <Button 
                  component={Link} 
                  to={`/product/${product.asin}`} 
                  variant="outlined" 
                  style={{ marginTop: '10px', marginRight: '10px' }}
                >
                  View Details
                </Button>
                <Button 
                  component={Link} 
                  to={`/product/${product.asin}/offers`} 
                  variant="outlined" 
                  style={{ marginTop: '10px' }}
                >
                  View Offers
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
        message={error}
      />
    </Container>
  );
};

export default ProductSearch;