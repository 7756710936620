// src/components/AdminPortal.js

import React, { useState } from 'react';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import AdminLogin from './AdminLogin';
import UserManagement from './UserManagement';
import RequestLogs from './RequestLogs';
import Button from '@mui/material/Button';

const AdminPortal = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const handleLogin = (token) => {
    localStorage.setItem('adminToken', token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsLoggedIn(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!isLoggedIn) {
    return <AdminLogin onLogin={handleLogin} />;
  }

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Admin Portal
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="User Management" />
          <Tab label="Request Logs" />
        </Tabs>
      </Box>
      {activeTab === 0 && <UserManagement />}
      {activeTab === 1 && <RequestLogs />}
      <Box mt={2}>
        <Button variant="contained" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
    </Container>
  );
};

export default AdminPortal;