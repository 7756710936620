// src/components/RequestLogs.js

import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Button, Typography, CircularProgress, TextField
} from '@mui/material';
import { getAllRequests, downloadRequestsData } from '../services/adminApi';

const RequestLogs = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const data = await getAllRequests();
      setRequests(data);
      setLoading(false);
    } catch (err) {
      setError('Error fetching requests');
      setLoading(false);
    }
  };

  const handleDownloadRequests = async () => {
    try {
      await downloadRequestsData();
    } catch (err) {
      setError('Error downloading request data');
    }
  };

  const filteredRequests = requests.filter(request => {
    const userEmail = request.userId?.email || 'Unknown';
    return (
      userEmail.toLowerCase().includes(filter.toLowerCase()) ||
      request.endpoint.toLowerCase().includes(filter.toLowerCase()) ||
      request.method.toLowerCase().includes(filter.toLowerCase()) ||
      request.requestType.toLowerCase().includes(filter.toLowerCase())
    );
  });

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleDownloadRequests} style={{ marginBottom: '1rem' }}>
        Download Requests Data
      </Button>
      <TextField
        fullWidth
        label="Filter requests"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        style={{ marginBottom: '1rem' }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User Email</TableCell>
              <TableCell>Endpoint</TableCell>
              <TableCell>Method</TableCell>
              <TableCell>Request Type</TableCell>
              <TableCell>Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRequests.map((request) => (
              <TableRow key={request._id}>
                <TableCell>{request.userId?.email || 'Unknown'}</TableCell>
                <TableCell>{request.endpoint}</TableCell>
                <TableCell>{request.method}</TableCell>
                <TableCell>{request.requestType}</TableCell>
                <TableCell>{new Date(request.timestamp).toLocaleString()}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RequestLogs;