import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { applyActionCode } from 'firebase/auth';
import { firebaseAuth } from '../utils/firebaseConfig';
import { Container, Typography, CircularProgress } from '@mui/material';

const EmailVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      const urlParams = new URLSearchParams(location.search);
      const oobCode = urlParams.get('oobCode');

      if (oobCode) {
        try {
          await applyActionCode(firebaseAuth, oobCode);
          setVerificationStatus('Email verified successfully!');
          setTimeout(() => navigate('/login'), 3000);
        } catch (error) {
          console.error('Error verifying email:', error);
          setVerificationStatus('Email verification failed. Please try again.');
        }
      } else {
        setVerificationStatus('Invalid verification link.');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Email Verification
      </Typography>
      <Typography variant="body1">
        {verificationStatus}
      </Typography>
      {verificationStatus === 'Verifying...' && <CircularProgress style={{ marginTop: '20px' }} />}
    </Container>
  );
};

export default EmailVerification;