import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Button, Grid, Card, CardContent, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getProductDetails } from '../services/api';

const ProductDetails = () => {
  const { asin } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProductDetails();
  }, [asin]);

  const fetchProductDetails = async () => {
    try {
      setLoading(true);
      const response = await getProductDetails(asin);
      setProduct(response.data);
      setError('');
    } catch (err) {
      setError('Error fetching product details: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      await getProductDetails(asin, true);
    } catch (err) {
      setError('Error downloading product details');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!product) return <Typography>No product details available</Typography>;

  const offer = product.includedDataTypes.OFFERS && product.includedDataTypes.OFFERS[0];

  return (
    <Container>
      <Button component={Link} to="/dashboard">Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        {product.title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Carousel>
            {product.includedDataTypes.IMAGES && product.includedDataTypes.IMAGES.map((image, index) => (
              <div key={index}>
                <img src={image.large.url} alt={`${product.title} - Image ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" color="primary">
                {offer ? (offer.price.formattedPrice || `$${offer.price.value.amount} ${offer.price.value.currencyCode}`) : 'Price not available'}
              </Typography>
              <Typography variant="body1">
                ASIN: {product.asin}
              </Typography>
              {offer && (
                <>
                  <Typography variant="body1">Seller: {offer.merchant.name}</Typography>
                  <Typography variant="body1">Fulfillment: {offer.fulfillmentType}</Typography>
                  <Typography variant="body1">Availability: {offer.availability}</Typography>
                  <Typography variant="body1">Offer ID: {offer.offerId}</Typography>
                  <Typography variant="body1">Delivery: {offer.deliveryInformation}</Typography>
                </>
              )}
              <Typography variant="body1" paragraph>
                {product.productDescription}
              </Typography>
              <List>
                {product.features && product.features.map((feature, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={feature} />
                  </ListItem>
                ))}
              </List>
              <Button 
                component={Link} 
                to={`/product/${asin}/offers`} 
                variant="contained" 
                color="primary" 
                style={{ marginRight: '10px' }}
              >
                View Offers
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleDownload}>
                Download Details
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ProductDetails;