import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Grid, Paper, Snackbar, CircularProgress, IconButton, Switch, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { placeOrder, getUserProfile, getProductOffers } from '../services/api';

const PlaceOrder = () => {
  const [user, setUser] = useState(null);
  const [lineItems, setLineItems] = useState([{ asin: '', quantity: 1, offerId: '' }]);
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState('');
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    fullName: '',
    phoneNumber: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    stateOrRegion: '',
    postalCode: '',
    countryCode: 'US'
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [orderResult, setOrderResult] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setUser(response.data);
    } catch (error) {
      setError('Error fetching user profile');
    }
  };

  // const handleAddLineItem = () => {
  //   setLineItems([...lineItems, { asin: '', quantity: 1, offerId: '' }]);
  // };

  const handleAddLineItem = () => {
    setLineItems([...lineItems, { asin: '', quantity: 1 }]);
  };

  const handleRemoveLineItem = (index) => {
    const newLineItems = lineItems.filter((_, i) => i !== index);
    setLineItems(newLineItems);
  };

  const handleLineItemChange = async (index, field, value) => {
    const newLineItems = [...lineItems];
    newLineItems[index][field] = value;
    setLineItems(newLineItems);

    if (field === 'asin' && value.length === 10) {
      try {
        setIsLoading(true);
        const response = await getProductOffers(value);
        if (response.data && response.data.featuredOffer) {
          newLineItems[index].offerId = response.data.featuredOffer.offerId;
          setLineItems([...newLineItems]);
        } else {
          console.log('No featured offer found for ASIN:', value);
        }
      } catch (error) {
        console.error('Error fetching offer ID:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleShippingAddressChange = (field, value) => {
    setShippingAddress({ ...shippingAddress, [field]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setMessage('');
    const orderData = {
      lineItems,
      purchaseOrderNumber,
      groupIdentifier: user.groupIdentifier,
      userEmail: user.amazonEmail || user.email,
      isTrialMode,
      shippingAddress
    };
    console.log('Sending order request:', JSON.stringify(orderData, null, 2));
    try {
      const response = await placeOrder(orderData);
      setMessage(`Order placed successfully. Amazon Order ID: ${response.data.amazonOrderId}`);
      setOrderResult(response.data);
    } catch (err) {
      setError('Error placing order: ' + (err.response?.data?.error || err.message));
    } finally {
      setIsLoading(false);
    }
  };

  const renderOrderResult = () => {
    if (!orderResult) return null;

    return (
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h6">Order Result</Typography>
        <Typography>Status: {orderResult.status}</Typography>
        <Typography>Message: {orderResult.message}</Typography>
        <Typography>Amazon Order ID: {orderResult.amazonOrderId}</Typography>
        <Typography>External ID: {orderResult.externalId}</Typography>
        <Typography>Subtotal: ${orderResult.subtotal.toFixed(2)}</Typography>
        <Typography>Tax: ${orderResult.tax.toFixed(2)}</Typography>
        <Typography>Shipping Cost: ${orderResult.shippingCost.toFixed(2)}</Typography>
        <Typography>Free Shipping Applied: {orderResult.freeShippingApplied ? 'Yes' : 'No'}</Typography>
        <Typography variant="h6" style={{marginTop: '10px'}}>Accepted Items:</Typography>
        {orderResult.acceptedItems.map((item, index) => (
          <Paper key={index} elevation={1} style={{padding: '10px', margin: '10px 0'}}>
            <Typography>ASIN: {item.asin}</Typography>
            <Typography>Quantity: {item.quantity}</Typography>
            <Typography>Price: ${item.price.toFixed(2)}</Typography>
            <Typography>Subtotal: ${item.subtotal.toFixed(2)}</Typography>
          </Paper>
        ))}
        {orderResult.rejectedItems.length > 0 && (
          <>
            <Typography variant="h6" style={{marginTop: '10px'}}>Rejected Items:</Typography>
            {orderResult.rejectedItems.map((item, index) => (
              <Paper key={index} elevation={1} style={{padding: '10px', margin: '10px 0'}}>
                <Typography>ASIN: {item.asin}</Typography>
                <Typography>Quantity: {item.quantity}</Typography>
                <Typography>Reason: {item.reason}</Typography>
              </Paper>
            ))}
          </>
        )}
      </Paper>
    );
  };

  return (
    <Container>
      <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Place Order
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <form onSubmit={handleSubmit}>
          {lineItems.map((item, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item xs={3}>
                <TextField
                  label="ASIN"
                  value={item.asin}
                  onChange={(e) => handleLineItemChange(index, 'asin', e.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Quantity"
                  type="number"
                  value={item.quantity}
                  onChange={(e) => handleLineItemChange(index, 'quantity', parseInt(e.target.value))}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Offer ID (Optional)"
                  value={item.offerId}
                  onChange={(e) => handleLineItemChange(index, 'offerId', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleRemoveLineItem(index)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button onClick={handleAddLineItem} style={{ marginTop: '10px' }}>Add Another Item</Button>
          
          <TextField
            label="Purchase Order Number"
            value={purchaseOrderNumber}
            onChange={(e) => setPurchaseOrderNumber(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          
          <Typography variant="h6" style={{ marginTop: '20px' }}>Shipping Address</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Name"
                value={shippingAddress.fullName}
                onChange={(e) => handleShippingAddressChange('fullName', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Phone Number"
                value={shippingAddress.phoneNumber}
                onChange={(e) => handleShippingAddressChange('phoneNumber', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Company Name"
                value={shippingAddress.companyName}
                onChange={(e) => handleShippingAddressChange('companyName', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address Line 1"
                value={shippingAddress.addressLine1}
                onChange={(e) => handleShippingAddressChange('addressLine1', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address Line 2"
                value={shippingAddress.addressLine2}
                onChange={(e) => handleShippingAddressChange('addressLine2', e.target.value)}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                value={shippingAddress.city}
                onChange={(e) => handleShippingAddressChange('city', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="State/Region"
                value={shippingAddress.stateOrRegion}
                onChange={(e) => handleShippingAddressChange('stateOrRegion', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Postal Code"
                value={shippingAddress.postalCode}
                onChange={(e) => handleShippingAddressChange('postalCode', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Country Code"
                value={shippingAddress.countryCode}
                onChange={(e) => handleShippingAddressChange('countryCode', e.target.value)}
                required
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
          
          <FormControlLabel
            control={
              <Switch
                checked={isTrialMode}
                onChange={(e) => setIsTrialMode(e.target.checked)}
                color="primary"
              />
            }
            label="Trial Mode"
            style={{ marginTop: '20px' }}
          />
          
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginTop: '20px' }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Place Order'}
          </Button>
        </form>
      </Paper>
      
      {renderOrderResult()}
      
      <Snackbar
        open={!!error || !!message}
        autoHideDuration={6000}
        onClose={() => { setError(''); setMessage(''); }}
        message={error || message}
      />
    </Container>
  );
};

export default PlaceOrder;