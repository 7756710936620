import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Layout from './components/Layout';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ForgotPassword from './components/ForgotPassword';
import ProductSearch from './components/ProductSearch';
import ProductDetails from './components/ProductDetails';
import ProductOffers from './components/ProductOffers';
import MultiAsinSearch from './components/MultiAsinSearch';
import ProfilePage from './components/ProfilePage';
import UserRequests from './components/UserRequests';
import PlaceOrder from './components/PlaceOrder';
import OrderStatus from './components/OrderStatus';
import EmailVerification from './components/EmailVerification';
import AdminPortal from './components/AdminPortal';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/email-verified" element={<EmailVerification />} />
          <Route path="/admin" element={<AdminPortal />} />
          <Route
            path="/*"
            element={
              <PrivateRoute>
                <Layout>
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/product-search" element={<ProductSearch />} />
                    <Route path="/product/:asin" element={<ProductDetails />} />
                    <Route path="/product/:asin/offers" element={<ProductOffers />} />
                    <Route path="/multi-asin-search" element={<MultiAsinSearch />} />
                    <Route path="/profile" element={<ProfilePage />} />
                    <Route path="/user-requests" element={<UserRequests />} />
                    <Route path="/place-order" element={<PlaceOrder />} />
                    <Route path="/order-status" element={<OrderStatus />} />
                    <Route path="/" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;