import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Typography, Button, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import { getProductOffers } from '../services/api';

const ProductOffers = () => {
  const { asin } = useParams();
  const [offers, setOffers] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchProductOffers();
  }, [asin]);

  const fetchProductOffers = async () => {
    try {
      setLoading(true);
      const response = await getProductOffers(asin);
      setOffers(response.data);
      setError('');
    } catch (err) {
      setError('Error fetching product offers: ' + (err.response?.data?.error || err.message));
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      await getProductOffers(asin, true);
    } catch (err) {
      setError('Error downloading product offers');
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!offers) return <Typography>No offers available</Typography>;

  const allOffers = [offers.featuredOffer, ...(offers.offers || [])].filter(Boolean);

  return (
    <Container>
      <Button component={Link} to="/dashboard">Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Offers for ASIN: {asin}
      </Typography>
      <Button variant="outlined" color="secondary" onClick={handleDownload} style={{ marginBottom: '20px' }}>
        Download Offers
      </Button>
      <Grid container spacing={2}>
        {allOffers.map((offer, index) => (
          <Grid item xs={12} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6">{offer.merchant?.name || 'Unknown Merchant'}</Typography>
                <Typography>Price: {offer.price?.formattedPrice || `$${offer.price?.value?.amount} ${offer.price?.value?.currencyCode}` || 'N/A'}</Typography>
                <Typography>Condition: {offer.condition?.conditionValue || 'N/A'}</Typography>
                <Typography>Availability: {offer.availability || 'N/A'}</Typography>
                <Typography>Offer ID: {offer.offerId || 'N/A'}</Typography>
                <Typography>Fulfillment Type: {offer.fulfillmentType || 'N/A'}</Typography>
                <Typography>Delivery: {offer.deliveryInformation || 'N/A'}</Typography>
                {offer.quantityPrice && offer.quantityPrice.quantityPriceTiers && offer.quantityPrice.quantityPriceTiers.length > 0 && (
                  <>
                    <Typography variant="subtitle1">Quantity Discounts:</Typography>
                    {offer.quantityPrice.quantityPriceTiers.map((tier, idx) => (
                      <Typography key={idx}>
                        Quantity: {tier.quantity}, Price: {tier.price?.formattedPrice || 'N/A'}
                      </Typography>
                    ))}
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ProductOffers;