// File: frontend/src/services/api.js

import axios from 'axios';
import { downloadJson } from '../utils/downloadJson';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.engageloyal.com',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(response => {
  if (response.config.download) {
    downloadJson(response.data, `${response.config.download}.json`);
  }
  return response;
});

// Helper function to handle blob responses
const handleBlobResponse = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

// Authentication and User Management
export const register = (email, password) => api.post('/api/auth/register', { email, password });
export const login = async (email, password) => {
  const response = await api.post('/api/auth/login', { email, password });
  if (response.data.token) {
    localStorage.setItem('token', response.data.token);
    api.defaults.headers['Authorization'] = `Bearer ${response.data.token}`;
  }
  return response;
};
export const logout = () => api.post('/api/auth/logout');
export const getUserProfile = () => {
  console.log('Fetching user profile');
  return api.get('/api/auth/profile');
};

export const updateUserProfile = (data) => {
  console.log('Updating user profile with:', data);
  return api.put('/api/auth/profile', data);
};

export const changePassword = (currentPassword, newPassword) => 
  api.post('/api/auth/change-password', { currentPassword, newPassword });

export const forgotPassword = (email) => api.post('/api/auth/forgot-password', { email });

// Amazon Connection
export const getAmazonAuthUrl = () => api.get('/api/amazon/auth-url');
export const checkAmazonConnection = () => api.get('/api/amazon/check-connection');
export const getAmazonProfile = () => api.get('/api/amazon/profile');
export const testAmazonBusinessAPI = () => api.get('/api/amazon/test-business-api');
export const updateAmazonEmail = (amazonEmail) => api.post('/api/amazon/update-email', { amazonEmail });

// API Key Management
export const generateApiKey = (name) => api.post('/api/user/api-keys', { name });
export const listApiKeys = () => api.get('/api/user/api-keys');
export const deleteApiKey = (keyId) => api.delete(`/api/user/api-keys/${keyId}`);

// Product Search and Details
// export const searchProducts = async (keyword, download = false) => {
//   const response = await api.get('/amazon/search', { params: { keyword, download }, responseType: download ? 'blob' : 'json' });
//   if (download && response.data instanceof Blob) {
//     handleBlobResponse(response, `search_results_${keyword}.json`);
//   }
//   return response;
// };

export const searchProducts = async (keyword, download = false) => {
  const response = await api.post('/api/amazon/search', 
    { keywords: keyword, download },
    { responseType: download ? 'blob' : 'json' }
  );
  if (download && response.data instanceof Blob) {
    handleBlobResponse(response, `search_results_${keyword}.json`);
  }
  return response;
};

export const getProductDetails = async (asin, download = false) => {
  const response = await api.get(`/api/amazon/product/${asin}`, { params: { download }, responseType: download ? 'blob' : 'json' });
  if (download && response.data instanceof Blob) {
    handleBlobResponse(response, `product_details_${asin}.json`);
  }
  return response;
};

export const getProductOffers = async (asin, download = false) => {
  const response = await api.get(`/api/amazon/product/${asin}/offers`, { params: { download }, responseType: download ? 'blob' : 'json' });
  if (download && response.data instanceof Blob) {
    handleBlobResponse(response, `product_offers_${asin}.json`);
  }
  return response;
};

export const searchProductsByAsins = async (productIds, download = false) => {
  try {
    const response = await api.post('/api/amazon/search-by-asins', { productIds, download }, { 
      responseType: download ? 'blob' : 'json'
    });
    if (download && response.data instanceof Blob) {
      handleBlobResponse(response, `search_by_asins_results.json`);
    }
    return response;
  } catch (error) {
    console.error('Error searching products by ASINs:', error);
    throw error;
  }
};

// Order Management
export const placeOrder = (orderData) => api.post('/api/amazon/place-order', orderData);
export const getOrderStatus = (externalId) => api.get(`/api/amazon/order-status/${externalId}`);

// User Requests and History
export const getUserRequests = () => api.get('/api/user/requests');
export const downloadRequestJson = async (requestId) => {
  try {
    const response = await api.get(`/api/user/requests/${requestId}/download`, { 
      responseType: 'blob',
      timeout: 30000 // Increase timeout to 30 seconds
    });
    if (response.data instanceof Blob) {
      handleBlobResponse(response, `request_${requestId}.json`);
    } else {
      throw new Error('Unexpected response format');
    }
    return response;
  } catch (error) {
    console.error('Error downloading request JSON:', error);
    throw error;
  }
};

export default api;