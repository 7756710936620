import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, Typography, Button, Grid, Paper, TextField, Snackbar, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Tab, Box,
  Tooltip, IconButton
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getUserProfile, updateUserProfile, changePassword, generateApiKey, listApiKeys, deleteApiKey } from '../services/api';

const ProfilePage = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    amazonEmail: '',
    groupIdentifier: ''
  });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [apiKeys, setApiKeys] = useState([]);
  const [newKeyName, setNewKeyName] = useState('');
  const [newApiKey, setNewApiKey] = useState(null);
  const [copiedKey, setCopiedKey] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
    fetchApiKeys();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setUser(response.data);
      setFormData({
        amazonEmail: response.data.amazonEmail || '',
        groupIdentifier: response.data.groupIdentifier || ''
      });
    } catch (err) {
      setError('Error fetching user profile');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await updateUserProfile(formData);
      setMessage('Profile updated successfully');
      setUser(prevUser => ({
        ...prevUser,
        amazonEmail: response.data.user.amazonEmail,
        groupIdentifier: response.data.user.groupIdentifier
      }));
    } catch (err) {
      setError('Error updating profile: ' + (err.response?.data?.error || err.message));
    }
  };

  const handleChangePassword = async () => {
    setError('');
    setSuccessMessage('');

    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    try {
      const response = await changePassword(currentPassword, newPassword);
      if (response.data.success) {
        setSuccessMessage('Password changed successfully');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmPassword('');
      } else {
        setError(response.data.message || 'Failed to change password');
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Error changing password');
    }
  };

  const fetchApiKeys = async () => {
    try {
      const response = await listApiKeys();
      setApiKeys(response.data);
    } catch (err) {
      setError('Error fetching API keys');
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      const response = await generateApiKey(newKeyName);
      setNewApiKey(response.data.apiKey);
      setNewKeyName('');
      fetchApiKeys();
      setMessage('API key generated successfully');
    } catch (err) {
      setError('Error generating API key');
    }
  };

  const handleCopyApiKey = (key) => {
    navigator.clipboard.writeText(key).then(() => {
      setCopiedKey(key);
      setTimeout(() => setCopiedKey(''), 5000);
    });
  };

  const handleDeleteApiKey = async (keyId) => {
    try {
      await deleteApiKey(keyId);
      fetchApiKeys();
      setMessage('API key deleted successfully');
    } catch (err) {
      setError('Error deleting API key');
    }
  };

  const renderProfileTab = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>User Information</Typography>
          <TextField
            fullWidth
            label="Email"
            value={user?.email || ''}
            disabled
            margin="normal"
          />
          <TextField
            fullWidth
            name="amazonEmail"
            label="Amazon Email"
            value={formData.amazonEmail}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            name="groupIdentifier"
            label="Group Identifier"
            value={formData.groupIdentifier}
            onChange={handleInputChange}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleUpdateProfile}>
            Update Profile
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>Change Password</Typography>
          <TextField
            fullWidth
            type="password"
            label="Current Password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            type="password"
            label="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            type="password"
            label="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleChangePassword}>
            Change Password
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );

  const renderApiManagementTab = () => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>Generate API Key</Typography>
          <TextField
            fullWidth
            label="API Key Name"
            value={newKeyName}
            onChange={(e) => setNewKeyName(e.target.value)}
            margin="normal"
          />
          <Button variant="contained" color="primary" onClick={handleGenerateApiKey}>
            Generate API Key
          </Button>
          {newApiKey && (
            <Box mt={2}>
              <Typography variant="subtitle1">New API Key:</Typography>
              <Typography variant="body1">{newApiKey}</Typography>
              <Button
                startIcon={<ContentCopyIcon />}
                onClick={() => handleCopyApiKey(newApiKey)}
                variant="outlined"
                size="small"
                style={{ marginTop: '8px' }}
              >
                Copy
              </Button>
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <Typography variant="h6" gutterBottom>Your API Keys</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>API Key</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Last Used</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apiKeys.map((key) => (
                  <TableRow key={key.id}>
                    <TableCell>{key.name}</TableCell>
                    <TableCell>
                      {key.key}
                      <Tooltip title={copiedKey === key.key ? "Copied!" : "Copy to clipboard"}>
                        <IconButton onClick={() => handleCopyApiKey(key.key)} size="small">
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{new Date(key.createdAt).toLocaleString()}</TableCell>
                    <TableCell>{key.lastUsed ? new Date(key.lastUsed).toLocaleString() : 'Never'}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteApiKey(key.id)}>
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );

  return (
    <Container>
      <Button onClick={() => navigate('/dashboard')}>Back to Dashboard</Button>
      <Typography variant="h4" component="h1" gutterBottom>
        Profile
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      {message && <Typography color="primary">{message}</Typography>}
      {successMessage && <Typography color="success">{successMessage}</Typography>}
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="profile tabs">
          <Tab label="Profile Information" />
          <Tab label="API Management" />
        </Tabs>
      </Box>
      
      {tabValue === 0 && renderProfileTab()}
      {tabValue === 1 && renderApiManagementTab()}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!error || !!message || !!successMessage}
        autoHideDuration={6000}
        onClose={() => { setError(''); setMessage(''); setSuccessMessage(''); }}
        message={error || message || successMessage}
      />
    </Container>
  );
};

export default ProfilePage;