import React, { useState } from 'react';
import { getAmazonAuthUrl, updateAmazonEmail } from '../services/api';
import { Button, TextField, Typography } from '@mui/material';

const AmazonConnect = () => {
  const [error, setError] = useState('');
  const [amazonEmail, setAmazonEmail] = useState('');

  const handleConnect = async () => {
    try {
      if (!amazonEmail) {
        setError('Please enter your Amazon account email');
        return;
      }
      await updateAmazonEmail(amazonEmail);
      const response = await getAmazonAuthUrl();
      window.location.href = response.data.authUrl;
    } catch (err) {
      setError('Failed to initiate Amazon connection');
    }
  };

  return (
    <div>
      <Typography variant="h6">Connect your Amazon account</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <TextField
        fullWidth
        variant="outlined"
        label="Amazon Account Email"
        value={amazonEmail}
        onChange={(e) => setAmazonEmail(e.target.value)}
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handleConnect}>
        Connect Amazon Account
      </Button>
    </div>
  );
};

export default AmazonConnect;
