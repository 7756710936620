import React, { useState, useEffect } from 'react';
import { getUserProfile, checkAmazonConnection, logout } from '../services/api';
import AmazonConnect from './AmazonConnect';
import { Container, Typography, Button, Grid, Paper, TextField, Snackbar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [isAmazonConnected, setIsAmazonConnected] = useState(false);
  const [productDetailsAsin, setProductDetailsAsin] = useState('');
  const [productOffersAsin, setProductOffersAsin] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserProfile();
    checkAmazonConnectionStatus();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await getUserProfile();
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to fetch user profile');
    }
  };

  const checkAmazonConnectionStatus = async () => {
    try {
      const response = await checkAmazonConnection();
      setIsAmazonConnected(response.data.isConnected);
    } catch (error) {
      console.error('Error checking Amazon connection:', error);
      setError('Failed to check Amazon connection status');
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('token');
      navigate('/login');
    } catch (err) {
      console.error('Logout error:', err);
      setError('Failed to logout');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Dashboard
      </Typography>
      {user && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6">
                Welcome, {user.email}!
              </Typography>
              {isAmazonConnected ? (
                <Typography>Your Amazon account is connected.</Typography>
              ) : (
                <AmazonConnect />
              )}
            </Paper>
          </Grid>
          {isAmazonConnected && (
            <>
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="h6">Product Search</Typography>
                  <Button component={Link} to="/product-search" variant="contained" color="primary">
                    Go to Product Search
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="h6">Multi-ASIN Search</Typography>
                  <Button component={Link} to="/multi-asin-search" variant="contained" color="primary">
                    Go to Multi-ASIN Search
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="h6">Place Order</Typography>
                  <Button component={Link} to="/place-order" variant="contained" color="primary">
                    Go to Place Order
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="h6">Get Order Status</Typography>
                  <Button component={Link} to="/order-status" variant="contained" color="primary">
                    Go to Order Status
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="h6">Product Details</Typography>
                  <TextField
                    fullWidth
                    label="Enter ASIN"
                    value={productDetailsAsin}
                    onChange={(e) => setProductDetailsAsin(e.target.value)}
                    margin="normal"
                  />
                  <Button 
                    component={Link} 
                    to={`/product/${productDetailsAsin}`} 
                    variant="contained" 
                    color="primary"
                    disabled={!productDetailsAsin}
                  >
                    View Product Details
                  </Button>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Paper elevation={3} style={{ padding: '20px' }}>
                  <Typography variant="h6">Product Offers</Typography>
                  <TextField
                    fullWidth
                    label="Enter ASIN"
                    value={productOffersAsin}
                    onChange={(e) => setProductOffersAsin(e.target.value)}
                    margin="normal"
                  />
                  <Button 
                    component={Link} 
                    to={`/product/${productOffersAsin}/offers`} 
                    variant="contained" 
                    color="primary"
                    disabled={!productOffersAsin}
                  >
                    View Product Offers
                  </Button>
                </Paper>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h6">User Profile</Typography>
              <Button component={Link} to="/profile" variant="contained" color="primary">
                Go to Profile
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h6">Request History</Typography>
              <Button component={Link} to="/user-requests" variant="contained" color="primary">
                View Request History
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="secondary" onClick={handleLogout}>
              Logout
            </Button>
          </Grid>
        </Grid>
      )}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError('')}
        message={error}
      />
    </Container>
  );
};

export default Dashboard;