// src/services/adminApi.js

import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api.engageloyal.com',
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true // This line is important
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('adminToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  const response = await api.post('/api/admin/login', { username, password });
  return response.data;
};

export const getAllUsers = async () => {
  const response = await api.get('/api/admin/users');
  return response.data;
};

export const getUserDetails = async (userId) => {
  const response = await api.get(`/api/admin/user/${userId}`);
  return response.data;
};

export const getAllRequests = async () => {
  const response = await api.get('/api/admin/requests');
  return response.data;
};

export const getUserRequests = async (userId) => {
  const response = await api.get(`/api/admin/requests/${userId}`);
  return response.data;
};

export const banUser = async (userId) => {
  const response = await api.post(`/api/admin/ban/${userId}`);
  return response.data;
};

export const unbanUser = async (userId) => {
  const response = await api.post(`/api/admin/unban/${userId}`);
  return response.data;
};

export const downloadUsersData = async () => {
  const response = await api.get('/api/admin/download/users', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'users.csv');
  document.body.appendChild(link);
  link.click();
};

export const downloadRequestsData = async () => {
  const response = await api.get('/api/admin/download/requests', { responseType: 'blob' });
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'requests.csv');
  document.body.appendChild(link);
  link.click();
};